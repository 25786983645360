import React, {Component} from "react";
import Logo from "./Logo"
import {withRouter} from "react-router";
import {translate} from "react-i18next";

class TopMobile extends Component {
    render() {
        return (
            <div className={'menu-container vertical-center'}>
                <div className={'center'}>
                    <div className={'max-width width '}>
                        <div className={'flex-row'}>
                            <Logo {...this.props}/>
                            <div className={'width vertical-center '}>
                                <div className={'align-right width'}>
                                    <div className={'mobile-menu-icon'} onClick={this.props.action}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('header')(TopMobile));
