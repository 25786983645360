
const currency = [
    {
        id: "usd",
        name: "USD",
        flag: "usd",
        short: "$"
    },
    {
        id: "eur",
        name: "EUR",
        flag: "eur",
        short: "€"
    },
    {
        id: "chf",
        name: "CHF",
        flag: "chf",
        short: "₣"
    }
    //,
    // {
    //     id: "rub",
    //     name: "RUB",
    //     flag: "rub",
    //     short: "₽"
    // }
];

export default currency;
