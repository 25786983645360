import { withRouter} from 'react-router'
import {Component} from 'react';

class Scrolltotop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(Scrolltotop);