import React, {Component} from "react";
import currencys from "../../currency-list";
import {withRouter} from "react-router";
import {translate} from "react-i18next";

class CurrencyContainer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
            currency: "chf"
        }
    }

    componentDidMount() {
        let activeCurrency = localStorage.getItem("currency");
        let ok = false;
        for (let i = 0; i < currencys.length; i++) {
            if (currencys[i].id === activeCurrency) {
                ok = true
            }
        }
        if (activeCurrency === null || activeCurrency === undefined || activeCurrency === "" || ok === false) {
            activeCurrency = "chf";
        }
        this.setState({
            currency: activeCurrency
        })
    }

    setStatus(state) {
        this.setState(
            {
                show: state
            }
        )
    }

    setCurrency(currency) {
        localStorage.setItem("currency", currency.toString());
        this.setState({
            currency: currency
        })
        window.location.reload();
    }

    render() {
        let currency = "";
        let currencysNew = [];
        for (let i = 0; i < currencys.length; i++) {
            if (currencys[i].id === this.state.currency) {
                currency = currencys[i].name;
            } else {
                currencysNew.push(currencys[i]);
            }
        }
        return (
            <div className={'currency-container'}>
                <div className={'flag ' + this.state.currency} onMouseMove={() => !this.props.mobile && this.setStatus(true)} onClick={() => this.props.mobile && this.setStatus(true)}>{currency}</div>
                {this.state.show && (
                    <div className={'currency-container-menu shadow-box'} onMouseLeave={() => this.setStatus(false)}>
                        <>
                            <div
                                className={this.state.currency + ' active '}
                                key={currency}>
                                {currency}
                            </div>
                            <hr/>
                            {currencysNew.map(item =>
                                <React.Fragment key={item.id}>
                                    <div className={item.flag.toLowerCase() + ' '}
                                         onClick={() => this.setCurrency(item.id)}>
                                        {item.name}
                                    </div>
                                </React.Fragment>
                            )}
                        </>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(translate('header')(CurrencyContainer));

