import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/locale.json';
import ru from './locales/ru/locale.json';
import de from './locales/de/locale.json';
import es from './locales/es/locale.json';
import fr from './locales/fr/locale.json';

i18n
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        load: 'languageOnly',
        whitelist: [
            'en','ru','de','fr','es'
        ],
        initImmediate: true,
        resources: {
            en, ru, de, fr, es
        },
        react: {
            wait: true,
        },
        interpolation: {
            escapeValue: true
        }
    }).then(r => {

});

export default i18n;
