import React, {Component} from 'react';
import Footer from "./PublicFooter.js";
import Header from "./PublicHeader.js";
import i18n from "../i18n";
import langs from './../i18n/list'
import {translate} from 'react-i18next';
import {withRouter} from "react-router";

import '../css/main.scss';

class App extends Component {

    render() {

        let MyComponent = this.props.comp;
        let lang = this.props.match.params.lang;
        //console.log(i18n.languages);

        let err = true;
        for (let i = 0; i < langs.length; i++) {
            let item = langs[i];
            if (item.id === lang) {
                err = false;
            }
        }

        if (err || lang === 'undefined') {

            lang = 'en';
            if (this.props.link === "") {
                i18n.changeLanguage(lang).then(r => {

                });
                window.location.href = '/' + lang + this.props.link;
                return
            }
        }

        if (i18n.language !== lang) {
            i18n.changeLanguage(lang).then(r => {

            });
        }

        return (
            <div className={'main-container'}>
                <Header page={this.props.page} {...this.props}/>
                {this.props.page_type !== "auth" && <MyComponent {...this.props}/>}
                <Footer {...this.props}/>
            </div>
        )
    }
}

export default withRouter(translate('main')(App));

