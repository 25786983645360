import React, {Component} from 'react';
//import Loading from "./loading2";

class Button extends Component {
    render() {
        if (this.props.paddingRight) {
            return (
                <div style={{paddingRight: 20}}>
                    <Internal {...this.props}/>
                </div>
            )
        }
        if (this.props.paddingLeft) {
            return (
                <div style={{paddingLeft: 20}}>
                    <Internal {...this.props}/>
                </div>
            )
        }
        return (
            <div style={{paddingLeft: 0}}>
                <Internal {...this.props}/>
            </div>
        )
    }
}

export default Button;

class Internal extends Component {
    render() {
        const {i18n} = this.props;
        return (
            <>
                {this.props.disabled ?
                    (
                        <div className={this.props.small ? 'button small' : this.props.type}
                             style={{opacity: 0.3, cursor: 'default'}}>
                            <>{this.props.text}</>
                        </div>
                    ) : (
                        <div className={this.props.small ? 'button small' : this.props.type}
                             onClick={!this.props.submit ? ()=>this.props.history.push('/' + i18n.language + this.props.to): this.props.action}>
                            {this.props.loading ? (
                                <>
                                    <div style={{opacity: 0}}>
                                        {this.props.text}</div>
                                    <div className={'center'}><div className={'loaderButton'}/></div>
                                </>
                            ) : (
                                <>{this.props.text}</>
                            )}
                        </div>

                    )}
            </>
        )
    }
}
