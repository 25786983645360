import React, {Component} from "react";
import {withRouter} from "react-router";
import {translate} from "react-i18next";

class Logo extends Component {
    render() {
        const d = new Date();
        console.log(d.getMonth());
        console.log(d.getDate());

        if ((d.getDate() > 20 && d.getMonth() === 11) || (d.getDate() < 10 && d.getMonth() === 0)) {
            return (
              <div className={'logo-ny pointer'} onClick={() => this.props.history.push("/")}/>
            );
        } else {
            return (
              <div className={'logo pointer'} onClick={() => this.props.history.push("/")}/>
            );
        }
    }
}
export default withRouter(translate('header')(Logo));

