import AppPublic from "./containers/PublicApp.js";
import Loading from "./containers/Loading.js";
import Loadable from 'react-loadable';

//import Home from "./pages/home";
//import Dedicated from "./pages/dedicated"
//import DedicatedList from "./pages/dedicated_list"
//import VPS from "./pages/vps"
//import VPSList from "./pages/vps_list"
//import Cloud from "./pages/cloud"
//import DDoS from "./pages/ddos"
//import Colocation from "./pages/colocation"
//import CDN from "./pages/cdn"
//import Storage from "./pages/storage"
//import Location from "./pages/location"
//import Faq from "./pages/faq"
//import About from "./pages/about"
//import News from "./pages/news"
//import Contact from "./pages/contact"
//import Hardware from "./pages/hardware"
//import Status from "./pages/status"
//import Policy from "./pages/policy"
//import Tos from "./pages/tos"
//import Why from "./pages/why_solar_communications"
//import FutureReady from "./pages/future_ready"
//import Business from "./pages/business"
//import Network from "./pages/network"
//import Partners from "./pages/partners"
//import Apps from "./pages/apps"
//import er404 from "./pages/404/index"


const Home = Loadable({
  loader: () => import('./pages/home'),
  loading: Loading,
});

const Dedicated = Loadable({
  loader: () => import('./pages/dedicated'),
  loading: Loading,
});

const DedicatedList = Loadable({
  loader: () => import('./pages/dedicated_list'),
  loading: Loading,
});

const DedicatedListCrypto = Loadable({
  loader: () => import('./pages/dedicated_list_crypto'),
  loading: Loading,
});

const VPS = Loadable({
  loader: () => import('./pages/vps'),
  loading: Loading,
});

const VPSList = Loadable({
  loader: () => import('./pages/vps_list'),
  loading: Loading,
});

const Cloud = Loadable({
  loader: () => import('./pages/cloud'),
  loading: Loading,
});

const DDoS = Loadable({
  loader: () => import('./pages/ddos'),
  loading: Loading,
});

const Colocation = Loadable({
  loader: () => import('./pages/colocation'),
  loading: Loading,
});

const CDN = Loadable({
  loader: () => import('./pages/cdn'),
  loading: Loading,
});

const Storage = Loadable({
  loader: () => import('./pages/storage'),
  loading: Loading,
});

const Location = Loadable({
  loader: () => import('./pages/location'),
  loading: Loading,
});

const Faq = Loadable({
  loader: () => import('./pages/faq'),
  loading: Loading,
});

const About = Loadable({
  loader: () => import('./pages/about'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('./pages/news'),
  loading: Loading,
});

const Contact = Loadable({
  loader: () => import('./pages/contact'),
  loading: Loading,
});

const Hardware = Loadable({
  loader: () => import('./pages/hardware'),
  loading: Loading,
});

const Status = Loadable({
  loader: () => import('./pages/status'),
  loading: Loading,
});

const Policy = Loadable({
  loader: () => import('./pages/policy'),
  loading: Loading,
});

const Tos = Loadable({
  loader: () => import('./pages/tos'),
  loading: Loading,
});

const Why = Loadable({
  loader: () => import('./pages/why_solar_communications'),
  loading: Loading,
});

const FutureReady = Loadable({
  loader: () => import('./pages/future_ready'),
  loading: Loading,
});

const Business = Loadable({
  loader: () => import('./pages/business'),
  loading: Loading,
});

const Network = Loadable({
  loader: () => import('./pages/network'),
  loading: Loading,
});

const Partners = Loadable({
  loader: () => import('./pages/partners'),
  loading: Loading,
});

const Apps = Loadable({
  loader: () => import('./pages/apps'),
  loading: Loading,
});

const Vpn = Loadable({
  loader: () => import('./pages/vpn'),
  loading: Loading,
});

const Reseller = Loadable({
  loader: () => import('./pages/reseller'),
  loading: Loading,
});

const er404 = Loadable({
  loader: () => import('./pages/404'),
  loading: Loading,
});

//import loadable from '@loadable/component'

//const Home = loadable(() => import('./pages/home'), "<b>Loading</b>")
//const Dedicated = loadable(() => import('./pages/dedicated'))
//const DedicatedList = loadable(() => import('./pages/dedicated_list'))
//const VPS = loadable(() => import('./pages/vps'))
//const VPSList = loadable(() => import('./pages/vps_list'))
//const Cloud = loadable(() => import('./pages/cloud'))
//const DDoS = loadable(() => import('./pages/ddos'))
//const Colocation = loadable(() => import('./pages/colocation'))
//const CDN = loadable(() => import('./pages/cdn'))
//const Storage = loadable(() => import('./pages/storage'))
//const Location = loadable(() => import('./pages/location'))
//const Faq = loadable(() => import('./pages/faq'))
//const About = loadable(() => import('./pages/about'))


//const About = loadable(() => import('./pages/about'),"<b>Loading</b>")
// const About = Loadable({
//     loader: () => import('./my-component'),
//     loading: Loading,
// });


//const News = loadable(() => import('./pages/news'))
//const Contact = loadable(() => import('./pages/contact'))
//const Hardware = loadable(() => import('./pages/hardware'))
//const Status = loadable(() => import('./pages/status'))
//const Policy = loadable(() => import('./pages/policy'))
//const Tos = loadable(() => import('./pages/tos'))
//const Why = loadable(() => import('./pages/why_solar_communications'))
//const FutureReady = loadable(() => import('./pages/future_ready'))
//const Business = loadable(() => import('./pages/business'))
//const Network = loadable(() => import('./pages/network'))
//const Partners = loadable(() => import('./pages/partners'))
//const Apps = loadable(() => import('./pages/apps'))
//const er404 = loadable(() => import('./pages/404'))


const appRoutes = [
  //lang redirect
  {path: "/home", to: "/home", redirect: true},
  {path: "/products", to: "/products", redirect: true},
  {path: "/contact", to: "/contact", redirect: true},
  {path: "/privacy_policy", to: "/privacy_policy", redirect: true},
  {path: "/terms_of_service", to: "/terms_of_service", redirect: true},
  {path: "/network_status", to: "/network_status", redirect: true},
  {path: "/dedicated", to: "/dedicated", redirect: true},
  {path: "/dedicated/list", to: "/dedicated/list", redirect: true},
  {path: "/vps", to: "/vps", redirect: true},
  {path: "/vps/list", to: "/vps/list", redirect: true},
  {path: "/cloud", to: "/cloud", redirect: true},
  {path: "/ddos", to: "/ddos", redirect: true},
  {path: "/colocation", to: "/colocation", redirect: true},
  {path: "/cdn", to: "/cdn", redirect: true},
  {path: "/storage", to: "/storage", redirect: true},
  {path: "/location", to: "/location", redirect: true},
  {path: "/faq", to: "/faq", redirect: true},
  {path: "/about", to: "/about", redirect: true},
  {path: "/about/test", to: "/about/test", redirect: true},

  {path: "/news", to: "/press", redirect: true},
  {path: "/press", to: "/press", redirect: true},
  {path: "/press/:id", to: "/press/:id", redirect: true},
  {path: "/:lang/news", to: "/press", redirect: true},
  {path: "/hardware", to: "/hardware", redirect: true},
  {path: "/why_solar_communications", to: "/why_solar_communications", redirect: true},
  {path: "/future_ready", to: "/future_ready", redirect: true},
  {path: "/business_safety_guarantee", to: "/business_safety_guarantee", redirect: true},
  {path: "/network_connectivity", to: "/network_connectivity", redirect: true},
  {path: "/partners", to: "/partners", redirect: true},
  {path: "/apps", to: "/apps", redirect: true},
  {path: "/vpn", to: "/vpn", redirect: true},
  {path: "/reseller", to: "/reseller", redirect: true},

  {path: "/buy_server_with_bitcoin", to: "/buy_server_with_bitcoin", redirect: true},
  {path: "/buy_server_with_ethereum", to: "/buy_server_with_ethereum", redirect: true},
  {path: "/buy_server_with_usdt", to: "/buy_server_with_usdt", redirect: true},
  {path: "/buy_server_with_monero", to: "/buy_server_with_monero", redirect: true},
  {path: "/buy_server_with_xrp", to: "/buy_server_with_xrp", redirect: true},
  {path: "/buy_server_with_solana", to: "/buy_server_with_solana", redirect: true},
  {path: "/buy_server_with_usdc", to: "/buy_server_with_usdc", redirect: true},
  {path: "/buy_server_with_litecoin", to: "/buy_server_with_litecoin", redirect: true},
  {path: "/buy_server_with_near", to: "/buy_server_with_near", redirect: true},
  {path: "/buy_server_with_stellar", to: "/buy_server_with_stellar", redirect: true},
  {path: "/buy_server_with_tron", to: "/buy_server_with_tron", redirect: true},
  {path: "/buy_server_with_dogecoin", to: "/buy_server_with_dogecoin", redirect: true},
  {path: "/buy_server_with_cardano", to: "/buy_server_with_cardano", redirect: true},
  {path: "/buy_server_with_bnb", to: "/buy_server_with_bnb", redirect: true},


  //no redirect pages
  {
    path: "/:lang",
    page_type: "content",
    link: "/home",
    name: "home",
    container: AppPublic,
    comp: Home,
    public_menu: false
  },
  {
    path: "/:lang/home",
    page_type: "content",
    link: "/home",
    name: "home",
    container: AppPublic,
    comp: Home,
    public_menu: false
  },
  {
    path: "/:lang/home/test",
    page_type: "content",
    link: "/home",
    name: "home",
    container: AppPublic,
    comp: Home,
    test: true,
    public_menu: false
  },
  {
    path: "/:lang/about",
    page_type: "content",
    link: "/about",
    name: "about",
    container: AppPublic,
    public_menu: false,
    comp: About,
  },
  {
    path: "/:lang/about/test",
    page_type: "content",
    link: "/about",
    name: "about",
    container: AppPublic,
    public_menu: false,
    test: true,
    comp: About,
  },
  {
    path: "/:lang/press",
    page_type: "content",
    link: "/press",
    name: "press",
    container: AppPublic,
    comp: News,
    public_menu: true
  },
  {
    path: "/:lang/press/:id",
    page_type: "content",
    link: "/press",
    name: "press",
    container: AppPublic,
    comp: News,
    public_menu: false
  },
  {
    path: "/:lang/hardware",
    page_type: "content",
    link: "/hardware",
    name: "hardware",
    container: AppPublic,
    comp: Hardware,
    public_menu: false
  },
  {
    path: "/:lang/partners",
    page_type: "content",
    link: "/partners",
    name: "partners",
    container: AppPublic,
    public_menu: true,
    comp: Partners,
  },
  {
    path: "/:lang/contact",
    page_type: "content",
    link: "/contact",
    name: "contact",
    container: AppPublic,
    comp: Contact,
    public_menu: true
  },
  {
    path: "/:lang/privacy_policy",
    page_type: "content",
    link: "/privacy_policy",
    name: "privacy_policy",
    container: AppPublic,
    comp: Policy,
    top_menu: true
  },
  {
    path: "/:lang/terms_of_service",
    page_type: "content",
    link: "/terms_of_service",
    name: "terms_of_service",
    container: AppPublic,
    comp: Tos,
    top_menu: true
  },
  {
    path: "/:lang/dedicated",
    page_type: "content",
    link: "/dedicated",
    name: "dedicated",
    container: AppPublic,
    comp: Dedicated,
  },
  {
    path: "/:lang/dedicated/list",
    page_type: "content",
    link: "/dedicated/list",
    name: "dedicated",
    container: AppPublic,
    comp: DedicatedList,
  },
  {
    path: "/:lang/vps",
    page_type: "content",
    link: "/vps",
    name: "vps",
    container: AppPublic,
    comp: VPS,
  },
  {
    path: "/:lang/vps/list",
    page_type: "content",
    link: "/vps/list",
    name: "vps",
    container: AppPublic,
    comp: VPSList,
  },
  {
    path: "/:lang/cloud",
    page_type: "content",
    link: "/cloud",
    name: "cloud",
    container: AppPublic,
    comp: Cloud,
  },
  {
    path: "/:lang/ddos",
    page_type: "content",
    link: "/ddos",
    name: "ddos",
    container: AppPublic,
    comp: DDoS,
  },
  {
    path: "/:lang/colocation",
    page_type: "content",
    link: "/colocation",
    name: "colocation",
    container: AppPublic,
    comp: Colocation,
  },
  {
    path: "/:lang/cdn",
    page_type: "content",
    link: "/cdn",
    name: "cdn",
    container: AppPublic,
    comp: CDN,
  },
  {
    path: "/:lang/storage",
    page_type: "content",
    link: "/storage",
    name: "storage",
    container: AppPublic,
    comp: Storage,
  },
  {
    path: "/:lang/location",
    page_type: "content",
    link: "/location",
    name: "location",
    container: AppPublic,
    comp: Location,
  },
  {
    path: "/:lang/faq",
    page_type: "content",
    link: "/faq",
    name: "faq",
    container: AppPublic,
    comp: Faq,
  },
  {
    path: "/:lang/network_status",
    page_type: "content",
    link: "/network_status",
    name: "network_status",
    container: AppPublic,
    comp: Status,
    top_menu: true
  },
  {
    path: "/:lang/why_solar_communications",
    page_type: "content",
    link: "/why_solar_communications",
    name: "why_solar_communications",
    container: AppPublic,
    comp: Why,
  },
  {
    path: "/:lang/future_ready",
    page_type: "content",
    link: "/future_ready",
    name: "future_ready",
    container: AppPublic,
    comp: FutureReady,
  },
  {
    path: "/:lang/business_safety_guarantee",
    page_type: "content",
    link: "/business_safety_guarantee",
    name: "business_safety_guarantee",
    container: AppPublic,
    comp: Business,
  },
  {
    path: "/:lang/network_connectivity",
    page_type: "content",
    link: "/network_connectivity",
    name: "network_connectivity",
    container: AppPublic,
    comp: Network,
  },
  {
    path: "/:lang/apps",
    page_type: "content",
    link: "/apps",
    name: "apps",
    container: AppPublic,
    comp: Apps,
  },
  {
    path: "/:lang/vpn",
    page_type: "content",
    link: "/vpn",
    name: "vpn",
    container: AppPublic,
    comp: Vpn,
  },
  {
    path: "/:lang/reseller",
    page_type: "content",
    link: "/reseller",
    name: "reseller",
    container: AppPublic,
    comp: Reseller,
  },


  // for crypto

  {
    path: "/:lang/buy_server_with_bitcoin",
    page_type: "content",
    link: "/buy_server_with_bitcoin",
    name: "bitcoin",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_ethereum",
    page_type: "content",
    link: "/buy_server_with_ethereum",
    name: "ethereum",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_usdt",
    page_type: "content",
    link: "/buy_server_with_usdt",
    name: "usdt",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_monero",
    page_type: "content",
    link: "/buy_server_with_monero",
    name: "monero",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_solana",
    page_type: "content",
    link: "/buy_server_with_solana",
    name: "solana",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_usdc",
    page_type: "content",
    link: "/buy_server_with_usdc",
    name: "usdc",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_bnb",
    page_type: "content",
    link: "/buy_server_with_bnb",
    name: "bnb",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_litecoin",
    page_type: "content",
    link: "/buy_server_with_litecoin",
    name: "litecoin",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_near",
    page_type: "content",
    link: "/buy_server_with_near",
    name: "near",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_stellar",
    page_type: "content",
    link: "/buy_server_with_stellar",
    name: "stellar",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_tron",
    page_type: "content",
    link: "/buy_server_with_tron",
    name: "tron",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_dogecoin",
    page_type: "content",
    link: "/buy_server_with_dogecoin",
    name: "dogecoin",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  {
    path: "/:lang/buy_server_with_cardano",
    page_type: "content",
    link: "/buy_server_with_cardano",
    name: "cardano",
    container: AppPublic,
    comp: DedicatedListCrypto,
  },
  //404
  {path: "/", to: "/home", redirect: true},
  {path: "", link: "", container: er404, page_type: "content", name: "404"},

];


export default appRoutes
