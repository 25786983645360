import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import appRoutes from "../../routes";
import Button from "../../modules/Button";
import {withRouter} from "react-router";
import {translate} from "react-i18next";
import LangContainer from "./LangContainer"
import CurrencyContainer from "./CurrenyContainer"

class MenuMobile extends Component {

  changeTheme() {
    let theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      theme = 'light';
      this.setState({
        theme: "Dark mode"
      })
    } else {
      theme = 'dark'
      this.setState({
        theme: "Light mode"
      })
    }
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute("data-theme", theme);
  }

  componentDidMount() {
    let theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      //theme = 'light';
      this.setState({
        theme: "Light mode"
      })
    } else {
      //theme = 'dark'
      this.setState({
        theme: "Dark mode"
      })
    }
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      theme: ""
    }
  }

  changeLinkIDvariable(link) {
    if (link === undefined) {
      return "";
    } else {
      return link.replace(":id", this.props.match.params.id);
    }
  }

  render() {
    let i18n = this.props.i18n;
    const {
      t,
    } = this.props;

    return (
      <>
        <div className={'menu-container-mobile'}>
          {(this.props.width > 720) ? null : (
            <div>
              <>
                <div className={'mobile-menu-item'}>
                  <div className={'mobile-pref width'}>
                    <div className={'moon'}
                         onClick={() => this.changeTheme()}>{this.state.theme}</div>
                    <div><LangContainer {...this.props} mobile={true}/></div>
                    <CurrencyContainer {...this.props} mobile={true}/>

                  </div>
                </div>
                <>
                  <NavLink key={'home'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/home"}>
                    {t('menu.home')}
                  </NavLink>

                  <NavLink key={'about'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/about"}>
                    {t('menu.about')}
                  </NavLink>

                  <NavLink key={'dedicated'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/dedicated"}>
                    {t('menu.dedicated')}
                  </NavLink>

                  <NavLink key={'cloud'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/cloud"}>
                    {t('menu.cloud')}
                  </NavLink>

                  <NavLink key={'vps'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/vps"}>
                    {t('menu.vps'
                    )}
                  </NavLink>

                  <NavLink key={'ddos'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/ddos"}>
                    {t('menu.ddos')}
                  </NavLink>

                  <NavLink key={'colocation'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/colocation"}>
                    {t('menu.colocation')}
                  </NavLink>

                  <NavLink key={'vpn'} className={'mobile-menu-item'}
                           to={"/" + i18n.language + "/vpn"}>
                    {t('menu.vpn')}
                  </NavLink>
                </>
                {
                  appRoutes.map((prop, key) => {
                    if (!prop.redirect) {
                      if (prop.public_menu) {
                        return (
                          <NavLink key={key} className={'mobile-menu-item'}
                                   to={"/" + i18n.language + prop.link}
                                   activeClassName="active">
                            {t('menu.' + prop.name)}
                          </NavLink>

                        );
                      }
                    }
                    return null;
                  })
                }
                <div className={'get-started-mobile'}>
                  <Button type={'button'} text={t('menu.get-started')} submit
                          action={() => window.location.href = '//' + process.env.REACT_APP_MEMBER_DOMAIN + '/' + i18n.language + '/login'}/>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(translate('header')(MenuMobile));

