import React, {Component} from "react";
import appRoutes from "../../routes";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import {translate} from "react-i18next";
import LangContainer from "./LangContainer"
import CurrencyContainer from "./CurrenyContainer"

class TopMenu extends Component {

    changeTheme() {
        let theme = localStorage.getItem('theme');
        if (theme === 'dark') {
            theme = 'light';
        } else {
            theme = 'dark'
        }
        localStorage.setItem('theme', theme);
        document.documentElement.setAttribute("data-theme", theme);
    }

    render() {
        const {i18n, t} = this.props;
        return (
            <div className={'top-menu'}>
                <div className={'center'}>
                    <div className={'max-width width flex-row'}>
                        <div className={'left'}>
                            {
                                appRoutes.map((prop, key) => {
                                    if (!prop.redirect) {
                                        if (prop.top_menu) {
                                            return (
                                                <NavLink key={key} to={"/" + i18n.language + prop.link}
                                                         activeClassName="active">
                                                    {t('menu.' + prop.name)}
                                                </NavLink>
                                            );
                                        }
                                    }
                                    return null;
                                })
                            }
                        </div>
                        <div className={'right'}>
                            <div className={'moon'} onClick={() => this.changeTheme()}/>
                            <LangContainer {...this.props}/>
                            <CurrencyContainer {...this.props}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(translate('header')(TopMenu));
