
const langs = [
    {
        enable: true,
        id: "en",
        name: "English",
        flag: "EN"
    },
    {
        enable: true,
        id: "de",
        name: "Deutsch",
        flag: "DE"
    },
    {
        enable: true,
        id:"fr",
        name:"Français",
        flag:"FR"
    },
    {
        enable: true,
        id:"es",
        name: "Español",
        flag:"ES"
    },
    {
        enable: true,
        id: "ru",
        name: "Русский",
        flag: "RU"
    },
];

export default langs;
