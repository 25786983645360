import React, {Component} from "react";
import styled from "styled-components";
import {Link, NavLink} from "react-router-dom";
import appRoutes from "../../routes";
import Button from "../../modules/Button";
import {withRouter} from "react-router";
import {translate} from "react-i18next";
import Logo from "./Logo"

class Menu extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      product_menu: false,
      about_menu: false,
      hardware_menu: false,
      leftProductMenu: 0,
      leftAboutMenu: 0,
      leftHardwareMenu: 0
    }
    this.product_menu_container_ref = React.createRef();
    this.about_menu_container_ref = React.createRef();
    this.hardware_menu_container_ref = React.createRef();

  }

  showHideMenu = (s, _) => {
    this.setState(
      {
        product_menu: s,
        about_menu: s,
        hardware_menu: s
      }
    )
  }

  showMenu = (menu, s) => {
    //console.log(menu, s);

    switch (menu) {
      case "product_menu":
        this.setState({
          product_menu: s,
          about_menu: false,
          hardware_menu: false,
        })
        break;
      case "about_menu":
        this.setState({
          product_menu: false,
          about_menu: s,
          hardware_menu: false,
        })
        break;
      case "hardware_menu":
        this.setState({
          hardware_menu: s,
          product_menu: false,
          about_menu: false
        })
        break;
      default:
        break;
    }
  }

  changeLinkIDvariable(link) {
    if (link === undefined) {
      return "";
    } else {
      return link.replace(":id", this.props.match.params.id);
    }
  }


  componentDidMount() {
    if (!this.props.mobile) {
      this.setState({
          leftProductMenu: (window.getComputedStyle(this.product_menu_container_ref.current, null).getPropertyValue("width").replace("px", "") / 2) + "px",
          leftAboutMenu: (window.getComputedStyle(this.about_menu_container_ref.current, null).getPropertyValue("width").replace("px", "") / 2) + "px",
          leftHardwareMenu: (window.getComputedStyle(this.hardware_menu_container_ref.current, null).getPropertyValue("width").replace("px", "") / 2) + "px",
        }
      )
    }
  }

  render() {
    let i18n = this.props.i18n;
    const {
      t,
    } = this.props;

    const ProductMenu = styled.div`
      ::after {
        left: ${this.state.leftProductMenu};
      }

      ::before {
        left: ${this.state.leftProductMenu};
      }
    `;

    const AboutMenu = styled.div`
      ::after {
        left: ${this.state.leftAboutMenu};
      }

      ::before {
        left: ${this.state.leftAboutMenu};
      }
    `;

    const HardwareMenu = styled.div`
      ::after {
        left: ${this.state.leftHardwareMenu};
      }

      ::before {
        left: ${this.state.leftHardwareMenu};
      }
    `;

    return (
      <div className={'menu-container vertical-center'} onMouseLeave={() => this.showHideMenu(false)}>
        <div className={'center'}>
          <div className={'max-width width '}>
            <div className={'flex-row'}>
              <Logo {...this.props}/>
              <div className={'width vertical-center '}>
                <menu className={'width align-right'}>
                  <div className={'menu-display width '}>
                    <NavLink key={'home'}
                             onMouseEnter={() => this.showHideMenu(false, 0)}
                             to={"/" + i18n.language + "/home"}>
                      {t('menu.home')}
                    </NavLink>
                    <div className="a" key={'product-container'}
                         ref={this.product_menu_container_ref}
                         onMouseEnter={() => this.showMenu("product_menu", true)}
                    >
                      {t('menu.products')}
                      {this.state.product_menu && (
                        <ProductMenu className={'product-menu-container box '}
                          //style={styleProductMenu}
                                     onMouseLeave={() => this.showHideMenu(false, 1)}>
                          <NavLink key={'dedicated'}
                                   activeClassName = {'nav-link ico dedicated'}
                                   className={'nav-link ico dedicated'}
                                   to={"/" + i18n.language + "/dedicated"}>
                            {t('menu.dedicated')}
                          </NavLink>
                          <NavLink key={'cloud ico'}
                                   className={'nav-link ico cloud'}
                                   to={"/" + i18n.language + "/cloud"}>
                            {t('menu.cloud')}
                          </NavLink>
                          <NavLink key={'vps'}
                                   className={'nav-link ico vps'}
                                   to={"/" + i18n.language + "/vps"}>
                            {t('menu.vps')}
                          </NavLink>
                          <NavLink key={'storage'}
                                   className={'nav-link ico storage'}
                                   to={"/" + i18n.language + "/storage"}>
                            {t('menu.storage')}
                          </NavLink>
                          <NavLink key={'apps'}
                                   className={'nav-link ico apps'}
                                   to={"/" + i18n.language + "/apps"}>
                            {t('menu.apps')}
                          </NavLink>
                          <NavLink key={'ddos'}
                                   className={'nav-link ico ddos'}
                                   to={"/" + i18n.language + "/ddos"}>
                            {t('menu.ddos')}
                          </NavLink>
                          <NavLink key={'colocation'}
                                   className={'nav-link ico colocation'}
                                   to={"/" + i18n.language + "/colocation"}>
                            {t('menu.colocation')}
                          </NavLink>
                          <NavLink key={'cdn'}
                                   className={'nav-link ico cdn'}
                                   to={"/" + i18n.language + "/cdn"}>
                            {t('menu.cdn')}
                          </NavLink>
                          <NavLink key={'vpn'}
                                   className={'nav-link ico vpn'}
                                   to={"/" + i18n.language + "/vpn"}>
                            {t('menu.vpn')}
                          </NavLink>
                        </ProductMenu>
                      )}
                    </div>
                    <div className="a" key={'about-container'}
                         ref={this.about_menu_container_ref}
                         onMouseEnter={() => this.showMenu("about_menu", true)}
                    >
                      {t('menu.about')}
                      {this.state.about_menu && (
                        <AboutMenu className={'product-menu-container box'}
                                   onMouseLeave={() => this.showHideMenu(false, 2)}>
                          <NavLink key={'about_key'}
                                   to={"/" + i18n.language + "/about"}>
                            {t('menu.company')}
                          </NavLink>
                          <NavLink key={'location_key'}
                                   to={"/" + i18n.language + "/location"}>
                            {t('menu.location')}
                          </NavLink>
                          <NavLink key={'why_key'}
                                   to={"/" + i18n.language + "/why_solar_communications"}>
                            {t('menu.why')}
                          </NavLink>
                          <Link key={'bsg'}
                                to={"/" + i18n.language + "/business_safety_guarantee"}>
                            {t('menu.business_safety_guarantee')}
                          </Link>
                        </AboutMenu>
                      )}
                    </div>
                    <div className="a" key={'hardware-container'}
                         ref={this.hardware_menu_container_ref}
                         onMouseEnter={() => this.showMenu("hardware_menu", true)}
                    >
                      {t('menu.technology')}
                      {this.state.hardware_menu && (
                        <HardwareMenu className={'product-menu-container box'}
                                      onMouseLeave={() => this.showHideMenu(false, 2)}>
                          <NavLink key={'hardware_key'}
                                   to={"/" + i18n.language + "/hardware"}>
                            {t('menu.hardware')}
                          </NavLink>
                          <NavLink key={'future_ready_key'}
                                   to={"/" + i18n.language + "/future_ready"}>
                            {t('menu.future_ready')}
                          </NavLink>
                          <NavLink key={'netwrok_ready_key'}
                                   to={"/" + i18n.language + "/network_connectivity"}>
                            {t('menu.network_connectivity')}
                          </NavLink>
                          <NavLink key={'faq_key'}
                                   to={"/" + i18n.language + "/faq"}>
                            {t('menu.faq')}
                          </NavLink>
                        </HardwareMenu>
                      )}
                    </div>
                    {
                      appRoutes.map((prop, key) => {
                        if (!prop.redirect) {
                          if (prop.public_menu) {
                            return (
                              <NavLink key={key}
                                       to={"/" + i18n.language + prop.link}
                                       onMouseEnter={() => this.showHideMenu(false, 3)}
                                       activeClassName="active">
                                {t('menu.' + prop.name)}
                              </NavLink>
                            );
                          }
                        }
                        return null;
                      })
                    }
                    <Button type={'button'} text={t('menu.get-started')} submit
                            action={() => window.location.href = '//' + process.env.REACT_APP_MEMBER_DOMAIN + '/' + i18n.language + '/login'}/>
                  </div>
                </menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(translate('header')(Menu));
