import React, {Component} from 'react';

import {translate} from 'react-i18next';
import {withRouter} from "react-router";
//import ClipLoader from "react-spinners/ClipLoader";
import {PuffLoader} from "react-spinners";
//import { css } from "@emotion/react";
//import '../css/main.scss';


class Loading extends Component {


  render() {
    return (
      // <div className={'pre-loading'}>
      // <div className={'loading'}>
      //
      // </div>
      // </div>
      <div className="outer">
        <div className={"inner"}>
          <PuffLoader size={60} color={"#4A90E2"} loading={true} speedMultiplier={1.5}/>
        </div>
      </div>


    )
  }
}

export default withRouter(translate('main')(Loading));

