import {Component} from 'react';
import {withRouter} from "react-router";
class PageRedirect extends Component {
    componentDidMount() {
        let path = this.props.path;
        if (this.props.match.params.id !== undefined) {
            path = path.replace(":id", "");
            this.props.history.push(path + this.props.match.params.id);
        } else {
            this.props.history.push(path);
        }
    }
    render() {
        return null
    }
}
export default withRouter(PageRedirect);
