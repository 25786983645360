import {API_URL} from "./config";

function request(query) {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    }
    fetch(API_URL + query, requestOptions)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          reject(error);
        }
        //console.log('ok' + query + " / " + JSON.stringify(data));
        //alert(JSON.stringify(data))
        resolve(data.data)
      })
      .catch(error => {
        console.error('There was an error!', error);
        reject(error.toString());
      });
  })
}

export const DEDICATED = request("/products/server.en.json");
export const CURRENCY = request("/variables.json");
export const VPS = request("/products/vps.en.json");
export const CLOUD = request("/products/cloud.en.json");
export const COLOCATION = request("/products/colocation.en.json");
export const DDOS = request("/products/protection.en.json");
export const CDN = request("/products/cdn.en.json");
