import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom'
import ScrollToTop from './modules/scrolltotop'
import {translate} from 'react-i18next';
import appRoutes from './routes.js';
import PageRedirect from "./redirect";
import {Helmet} from "react-helmet";
import langs from "./i18n/list";
import currency_list from "./currency-list";
import {CURRENCY} from "./api/index";

require('dotenv').config();

class App extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      theme: 'light',
      width: 0,
      mobile: false,
      showLangBlock: false,
      langs: langs,
      lang: "",
      menu: false,
      currency_rate: []
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    let mobile = false;
    if (window.innerWidth <= 720) {
      mobile = true;
    }
    // id change size width - detect and refresh state and props
    if (this.state.mobile !== mobile || this.state.width !== window.innerWidth) {
      this.setState({mobile: mobile, width: window.innerWidth});
      //alert(window.innerHeight);
    }
  }

  componentDidMount() {

    console.log("solar front version 2.0.3")

    if (!window.location.port && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {
      }
    }

    this.detectColorScheme();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    CURRENCY
      .then(rate => this.setState({currency_rate: rate.currencies}))

  }

  detectColorScheme() {
    let theme = "light";    //default to light

    //local storage is used to override OS theme settings
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "dark") {
        theme = "dark";
      }
    } else if (!window.matchMedia) {
      //matchMedia method not supported
      return false;
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      //OS theme setting detected as dark
      theme = "dark";
    }

    //dark theme preferred, set document with a `data-theme` attribute
    if (theme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem('theme', "dark")
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem('theme', "light")
    }

    this.setState({
      theme: theme
    })

  }

  render() {
    const {i18n} = this.props;
    let lang = i18n.language.split("-")[0];
    let currency = {};
    let activeCurrency = localStorage.getItem("currency");
    if (activeCurrency === null || activeCurrency === undefined || activeCurrency === "") {
      activeCurrency = "chf";
    }
    for (let i = 0; i < currency_list.length; i++) {
      if (currency_list[i].id === activeCurrency) {
        currency = currency_list[i];
      }
    }

    const rate = this.state.currency_rate;
    let activeRate = 0;

    for (let i = 0; i < rate.length; i++) {
      if (rate[i].short_name === activeCurrency.toUpperCase()) {
        activeRate = rate[i].rate;
      }
    }

    return (
      <>
        {/*<UpdateCurrency {...this.props} action={this.updateCurrency}/>*/}
        <div className={'after_root'}>
          {/*<Notifications/>*/}
          <ScrollToTop>
            {/*<Socket {...this.props}/>*/}
            <Switch>
              {
                appRoutes.map((prop, key) => {
                  if (prop.redirect)
                    return (
                      <PageRoute {...this.props}
                                 {...prop}
                                 exact
                                 component={(props) => <PageRedirect {...this.props} {...props}
                                                                     path={"/" + lang + prop.to}/>}
                                 key={key}
                      />
                    );
                  return (
                    <PageRoute {...this.props}
                               {...prop}
                               exact
                               key={key}
                               component={(props) => <prop.container
                                 {...props}
                                 {...this.props}
                                 {...prop}
                                 mobile={this.state.mobile}
                                 theme={this.state.theme}
                                 currency={currency}
                                 currency_rate={this.state.currency_rate}
                                 activeRate={activeRate}
                               />
                               }
                               comp={prop.comp}
                    />
                  );
                })
              }
            </Switch>

          </ScrollToTop>
        </div>
      </>
    );
  }
}

class PageRoute extends Route {
  render() {
    const {t} = this.props;
    let title = t('header:menu.' + this.props.name);
    const description = t('description:' + this.props.name + '.description');
    return (
      <>
        <Helmet>
          <title>{`${title} / Solar Communications`}</title>
          <meta name="description" content={description}/>
        </Helmet>
        <Route {...this.props}/>
      </>
    )
  }
}

export default translate(['description', 'header'])(App);


