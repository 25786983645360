import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { Route } from 'react-router-dom'

class GoogleAnalytics extends Component {
  componentDidMount () {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    )
    //ReactGA.send({ hitType: "pageview", page: "/my-path" });
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search)
    }
  }

  logPageChange (pathname, search = '') {
    const page = pathname + search
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    ReactGA.pageview(page)
    //ReactGA.send({ hitType: "pageview", page: "/my-path" });
  }

  render () {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
}

const RouteTracker = () =>
  <Route component={GoogleAnalytics} />

const init = (options = {}) => {
   ReactGA.initialize(
     "G-2X0CLC3P2R", {
     //"283567622", { //ga4
        debug: true,
        ...options
      }
    )
  return true
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init
}
