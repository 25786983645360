import React, {Component} from "react";
import langs from "../../i18n/list";
import {withRouter} from "react-router";
import {translate} from "react-i18next";

class LangContainer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
        }
    }

    changeLinkIDvariable(link) {
        if (link === undefined) {
            return "";
        } else {
            return link.replace(":id", this.props.match.params.id);
        }
    }

    setStatus(state) {
        this.setState(
            {
                show: state
            }
        )
    }

    render() {
        const {i18n} = this.props;
        let language = "";
        let langsNew = [];
        for (let i = 0; i < langs.length; i++) {
            if (langs[i].id === i18n.language) {
                language = langs[i].name;
            } else {
                langsNew.push(langs[i]);
            }
        }
        return (
            <div className={'lang-container'}>
                <div className={'flag ' + i18n.language} onMouseMove={() => !this.props.mobile && this.setStatus(true)}
                     onClick={() => this.props.mobile && this.setStatus(true)}>{language}</div>
                {this.state.show && (
                    <div className={'lang-container-menu shadow-box'} onMouseLeave={() => this.setStatus(false)}>
                        <>
                            <a className={i18n.language + ' active '}
                               href={'/' + i18n.language + this.changeLinkIDvariable(this.props.link)}
                               key={i18n.language}>
                                {language}
                            </a>
                            <hr/>
                            {langsNew.map(item =>
                                <React.Fragment key={item.id}>
                                    {item.enable ? (
                                        <a className={item.flag.toLowerCase() + ' '}
                                           href={'/' + item.id + this.changeLinkIDvariable(this.props.link)}
                                        >
                                            {item.name}
                                        </a>
                                    ) : (
                                        <div className={item.flag.toLowerCase() + ' disable'}>
                                            {item.name}
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                            }
                        </>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(translate('header')(LangContainer));
