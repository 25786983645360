import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {withRouter} from 'react-router'
import langs from './../i18n/list'
import Menu from "./Header/Menu";
import MenuMobile from "./Header/MenuMobile"
import TopMenu from "./Header/TopMenu";
import TopMobile from "./Header/TopMobile";

import './../css/header.scss';

class Header extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showLangBlock: false,
            langs: langs,
            lang: "",
            menu: false,
        }
    }

    detect = () => {
        let {i18n} = this.props;
        let getLang = i18n.language;

        //let getLang = localStorage.getItem('i18nextLng');

        let err = true;
        for (let i = 0; i < this.state.langs.length; i++) {
            let item = this.state.langs[i];
            if (item.id === getLang) {
                err = false;
            }
        }

        if (!err) {
            //i18n.changeLanguage(getLang);
            this.setState({
                lang: getLang
            });

        } else {
            console.log("error detect");
            //i18n.changeLanguage('en');
            this.setState({
                lang: 'en'
            });

        }

    };

    showHideMenuMobile = () => {
        this.setState(
            {
                menu: !this.state.menu
            }
        )
    }

    componentDidMount() {
        this.detect();
        let langMap = new Map();
        let langs = this.state.langs;
        for (let i = 0; i < langs.length; i++) {
            langMap.set(langs[i].id, langs[i])
        }

        this.setState({
            showLangBlock: false,
            langMap: langMap,
        });
    };

    render() {
        if (this.props.page_type === "content") {
            return (
                <header className={'header-public'}>
                    {!this.props.mobile ? (
                        <>
                            <TopMenu {...this.props} width={this.state.width}/>
                            <Menu {...this.props} width={this.state.width}/>
                        </>
                    ) : (
                        <>
                            <TopMobile {...this.props} width={this.state.width} action={this.showHideMenuMobile}/>
                            {this.state.menu && <MenuMobile {...this.props} width={this.state.width}/>}
                        </>
                    )}
                </header>
            );
        }
        return null
    }
}

export default withRouter(translate('header')(Header));
